<template>
  <div class="pagination">
    <AtomsButton
      :icon="{ name: 'arrow-left' }"
      rel="prev"
      class="pagination-arrow"
      text="Previous"
      :disabled="page <= 1"
      :to="{ id: types, query: { page: page - 1 } }"
    />
    <AtomsButton
      v-for="p in pages"
      :key="p"
      v-bind="paginate(p)"
      class="pagination-number"
    />
    <AtomsButton
      :icon="{ name: 'arrow-right' }"
      text="Next"
      rel="next"
      class="pagination-arrow"
      :appearance="['reverse']"
      :disabled="page >= totalPages"
      :to="{ id: types, query: { page: page + 1 } }"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  types: String,
  pages: { type: Array, default: () => [] },
  totalPages: { type: Number, default: 1 },
  page: { type: Number, default: 1 },
})

const paginate = (p) => ({
  key: p,
  rel:
    (p === props.page - 1 && 'prev') ||
    (p === props.page + 1 && 'next') ||
    (p === props.page && 'canonical') || null,
  class: [
    props.page === p ? 'active' : '',
    p === 1 ? (props.page > 4 ? 'first first-active' : 'first') : '',
    p === props.totalPages
      ? props.page < props.totalPages - 3 && props.totalPages > 5
        ? 'last last-active'
        : 'last'
      : '',
    props.totalPages > 10 &&
    !(
      props.page - (3 % props.totalPages) < p &&
      p < props.page + (3 % props.totalPages)
    )
      ? 'inactive'
      : '',
  ].filter((a) => a !== ''),
  to: { id: props.types, query: { page: p } },
  text: String(p),
})
</script>

<style lang="scss">
.pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: czn.$lg 0;

  .pagination {
    &-arrow {
      padding: czn.$rg czn.$md;
      margin: 0;
    }
    &-number {
      font-size: 0.8em;
      width: 2rem;
      padding: 0;
      margin: 0;
      max-width: 2rem;
      justify-content: center;
      text-align: center;
      transition: czn.transition(var(--transition), (max-width));

      &.first::after,
      &.last::before {
        position: absolute;

        display: inline-block;
        width: 2rem;
        max-width: 0;

        color: black;

        visibility: hidden;
        opacity: 0;

        content: '...';
        pointer-events: none;
      }
      &.first-active {
        margin-right: 2rem;
        &::after {
          left: 100%;
        }
      }
      &.last-active {
        margin-left: 2rem;
        &::before {
          right: 100%;
        }
      }
      &.first-active::after,
      &.last-active::before {
        max-width: 2rem;

        visibility: visible;
        opacity: 1;
      }

      &.active {
        color: var(--secondary);
      }
      &.inactive:not(.first):not(.last) {
        max-width: 0;

        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
</style>
